import React, {Component} from "react";
import translate from "redux-polyglot/translate";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {startTwilioCall, endTwilioCall, closeInspection} from "../../actions";
import {JoinCall} from "./JoinCall";
import {VideoRoom} from "./VideoRoom";
import {LoadingOutlined} from "@ant-design/icons";
import "../../style/inspector.css";
import "../twilio/assets/fonts/css/icons.css";
import {Button, notification, Result} from "antd";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";


export class VideocallInspectionContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            audioDevices: [],
            videoDevices: [],
            geolocator: null,
            currentAudioDevice: null,
            currentVideoDevice: null,
            noVideoSources: false,
            noAudioSources: false,
        };

        this.selectDevice = this.selectDevice.bind(this);
        this.startCall = this.startCall.bind(this);
    }

    selectDevice(kind, deviceId) {
        if (kind === "audioinput")
            this.setState({currentAudioDevice: deviceId});
        else if (kind === "videoinput")
            this.setState({currentVideoDevice: deviceId});
    }

    startCall(guestName, roomName, pwd) {
        this.props.startTwilioCall(guestName, roomName, pwd, this.state.currentAudioDevice, this.state.currentVideoDevice);
    }

    componentDidMount() {
        navigator.mediaDevices.getUserMedia({audio: true, video: true})
            .then(stream => {
                //stream.getTracks().forEach(track => track.stop());

                let userAudioDevices = [];
                let userVideoDevices = [];
                navigator.mediaDevices.enumerateDevices()
                    .then(devices => {
                        devices.forEach(device => {
                            if (device.deviceId !== "default" && device.deviceId !== "communications") {
                                if (device.kind === "audioinput")
                                    userAudioDevices.push(device);
                                else if (device.kind === "videoinput")
                                    userVideoDevices.push(device);
                            }
                        });

                        let geolocator = null;
                        if (navigator.geolocation) {
                            geolocator = navigator.geolocation;
                            geolocator.getCurrentPosition((pos) => {
                            }, () => {
                                geolocator = null;
                            });
                        }
                        this.setState({
                            geolocator: geolocator,
                            audioDevices: userAudioDevices,
                            videoDevices: userVideoDevices,
                            currentAudioDevice: userAudioDevices[0].deviceId,
                            currentVideoDevice: userVideoDevices.length > 0 ? userVideoDevices[0].deviceId : null,
                            noVideoSources: false,
                            noAudioSources: false,
                        });
                    });
            })
            .catch((e) => {
                navigator.mediaDevices.getUserMedia({audio: true, video: false})
                    .then(stream => {
                        //stream.getTracks().forEach(t => t.stop());
                        let userAudioDevices = [];
                        let userVideoDevices = [];
                        navigator.mediaDevices.enumerateDevices()
                            .then(devices => {
                                devices.forEach(device => {
                                    if (device.deviceId !== "default" && device.deviceId !== "communications") {
                                        if (device.kind === "audioinput")
                                            userAudioDevices.push(device);
                                        else if (device.kind === "videoinput")
                                            userVideoDevices.push(device);
                                    }
                                });

                                let geolocator = null;
                                if (navigator.geolocation) {
                                    geolocator = navigator.geolocation;
                                    geolocator.getCurrentPosition((pos) => {
                                    }, () => {
                                        geolocator = null;
                                    });
                                }
                                this.setState({
                                    geolocator: geolocator,
                                    audioDevices: userAudioDevices,
                                    videoDevices: userVideoDevices,
                                    currentAudioDevice: userAudioDevices[0].deviceId,
                                    currentVideoDevice: userVideoDevices.length > 0 ? userVideoDevices[0].deviceId : null,
                                    noVideoSources: true,
                                    noAudioSources: false,
                                });
                            });
                    }).catch((error) => {
                    this.setState({noVideoSources: true, noAudioSources: true});
                    notification.error({
                        message: this.props.p.t("inspection.no_audio_video_device"),
                        description: this.props.p.t("inspection.no_audio_video_device_description"),
                        duration: null
                    });
                });
            });
        navigator.mediaDevices.ondevicechange = (event) => {
            navigator.mediaDevices.getUserMedia({audio: true, video: true})
            .then(stream => {
                //stream.getTracks().forEach(track => track.stop());

                let userAudioDevices = [];
                let userVideoDevices = [];
                navigator.mediaDevices.enumerateDevices()
                    .then(devices => {
                        devices.forEach(device => {
                            if (device.deviceId !== "default" && device.deviceId !== "communications") {
                                if (device.kind === "audioinput")
                                    userAudioDevices.push(device);
                                else if (device.kind === "videoinput")
                                    userVideoDevices.push(device);
                            }
                        });

                        this.setState({
                            audioDevices: userAudioDevices,
                            videoDevices: userVideoDevices,
                            currentAudioDevice: userAudioDevices[0].deviceId,
                            currentVideoDevice: userVideoDevices.length > 0 ? userVideoDevices[0].deviceId : null,
                            noVideoSources: false,
                            noAudioSources: false,
                        });
                    });
            })
            .catch((e) => {
                navigator.mediaDevices.getUserMedia({audio: true, video: false})
                    .then(stream => {
                        //stream.getTracks().forEach(t => t.stop());
                        let userAudioDevices = [];
                        let userVideoDevices = [];
                        navigator.mediaDevices.enumerateDevices()
                            .then(devices => {
                                devices.forEach(device => {
                                    if (device.deviceId !== "default" && device.deviceId !== "communications") {
                                        if (device.kind === "audioinput")
                                            userAudioDevices.push(device);
                                        else if (device.kind === "videoinput")
                                            userVideoDevices.push(device);
                                    }
                                });
                                this.setState({
                                    audioDevices: userAudioDevices,
                                    videoDevices: userVideoDevices,
                                    noVideoSources: true,
                                    noAudioSources: false,
                                });
                            });
                    });
            });
        };
    }

    render() {
        return (
            <ErrorBoundary description={null}
                           message={<Result status="500"
                                            title="Oops!"
                                            subTitle={this.props.p.t("inspection.something_went_wrong")}
                                            extra={<Button type="primary" onClick={() => window.location.reload()}>
                                                {this.props.p.t("inspection.reload_page")}
                                            </Button>}/>}>
                <React.Fragment>
                    {this.props.isFetchingTwilio && !this.props.errorStartingCall && !this.props.callActive &&
                        <div className={"inspector-lobby"}>
                            <div className="inspector-lobby-message">
                                <p>{this.props.p.t("inspection.entering_inspection")}</p>
                            </div>
                            <div style={{textAlign: "center", fontSize: "5rem"}}>
                                <LoadingOutlined/>
                            </div>
                        </div>}
                    {!this.props.isFetchingTwilio && this.props.errorStartingCall && !this.props.callActive &&
                        <div className={"inspector-lobby"}>
                            <div className="inspector-lobby-message">
                                <p>{this.props.p.t("inspection.warning")}</p>
                                <p>{this.props.errorMessageStart}</p>
                            </div>
                        </div>
                    }
                    {!this.props.isFetchingTwilio && !this.props.errorStartingCall && !this.props.callActive &&
                        <JoinCall audioDevices={this.state.audioDevices}
                                  videoDevices={this.state.videoDevices}
                                  ondeviceselected={this.selectDevice}
                                  onstartcall={this.startCall}
                                  currentAudioDevice={this.state.currentAudioDevice}
                                  currentVideoDevice={this.state.currentVideoDevice}
                                  noAudioSources={this.state.noAudioSources}
                                  noVideoSources={this.state.noVideoSources}/>
                    }
                    {!this.props.isFetchingTwilio && !this.props.errorStartingCall && this.props.callActive &&
                        <VideoRoom audioDevices={this.state.audioDevices}
                                   videoDevices={this.state.videoDevices}
                                   currentAudioDevice={this.state.currentAudioDevice}
                                   currentVideoDevice={this.state.currentVideoDevice}
                                   ondeviceselected={this.selectDevice}
                                   geolocator={this.state.geolocator}
                                   noAudioSources={this.state.noAudioSources}
                                   noVideoSources={this.state.noVideoSources}
                                   closeInspection={room => this.props.endTwilioCall(room)}/>
                    }
                </React.Fragment>
            </ErrorBoundary>
        );
    }
}

function mapStateToProps(state) {
    return {
        callActive: state.inspection.twilioCallActive,
        isFetchingTwilio: state.inspection.isFetchingTwilio,
        errorMessageStart: state.inspection.errorMessageStart,
        errorStartingCall: state.inspection.errorStartingCall,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({startTwilioCall, endTwilioCall, closeInspection}, dispatch);
}

VideocallInspectionContainer = translate(VideocallInspectionContainer);

VideocallInspectionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(VideocallInspectionContainer);

import axios from "axios";
import {actions as notifActions} from "redux-notifications";
import {messageStatus, UNAUTHORIZED_ERROR} from "../../common/constants";
import {connect as twilioConnect, createLocalTracks, LocalDataTrack} from 'twilio-video';

const {notifSend} = notifActions;

const ROOT_URL = process.env.REACT_APP_API_URL;

export const OPEN_INSPECTION = "OPEN_INSPECTION";
export const CLOSE_INSPECTION = "CLOSE_INSPECTION";
export const START_TWILIO_CALL = "START_TWILIO_CALL";
export const STARTED_TWILIO_CALL = "STARTED_TWILIO_CALL";
export const END_TWILIO_CALL = "END_TWILIO_CALL";
export const MUTE_UNMUTE_CALL = "MUTE_UNMUTE_CALL";
export const SHOW_HIDE_CALL = "SHOW_HIDE_CALL";
export const UPLOAD_SCREENSHOT = "UPLOAD_SCREENSHOT";
export const START_TWILIO_RECORDING = "START_TWILIO_RECORDING";
export const STOP_TWILIO_RECORDING = "STOP_TWILIO_RECORDING";

export const START_RECORDING = "START_RECORDING";
export const STOP_RECORDING = "STOP_RECORDING";
export const TAKE_SCREENSHOT = "TAKE_SCREENSHOT";
export const SAVE_STREAMID_TO_RECORD = "SAVE_STREAMID_TO_RECORD";

export const LOAD_ALL_VIDEOS = "LOAD_ALL_VIDEOS";
export const LOAD_ALL_SCREENSHOTS = "LOAD_ALL_SCREENSHOTS";
export const SET_SELECTED_SCREENSHOT = "SET_SELECTED_SCREENSHOT";

export const CLEAR_VIDEOS_AND_SCREENSHOTS = "CLEAR_VIDEOS_AND_SCREENSHOTS";

export const ERROR_ENTER_CALL = "ERROR_ENTER_CALL";

// TWILIO VERSION ACTIONS

export const openInspection = () => {
  return {
    type: OPEN_INSPECTION,
  }
}

export const closeInspection = () => {
  return {
    type: CLOSE_INSPECTION,
  }
}

export const startTwilioCall = (identity, room, passcode, audioDeviceId, videoDeviceId) => {
  let _token = null;
  return (dispatch) => {
    dispatch({
      type: START_TWILIO_CALL,
    });
    let tokenUrl = process.env.REACT_APP_API_URL;

    return axios.post(tokenUrl, {room_name: room, passcode: passcode, identity: identity})
        .then((res) => {
          _token = res.data.token;
          let options = {
            audio: {
              deviceId: audioDeviceId,
              echoCancellation: true,
              noiseSuppression: true,
              autoGainControl: true
            },
          }
          if (videoDeviceId) {
            options = {
              audio: {
                deviceId: audioDeviceId,
                echoCancellation: true,
                noiseSuppression: true,
                autoGainControl: true
              },
              video: {deviceId: videoDeviceId}
            }
          }
          //console.log('TOKEN: ', _token);
          return createLocalTracks(options);
        })
        .then(tracks => {
          const dataTrack = new LocalDataTrack();  // add a DataTrack to pass messages/commands
          tracks.push(dataTrack);
          return tracks;
        })
        .then(tracks => {
          return twilioConnect(_token, {
            tracks: tracks,
            preferredVideoCodecs: [ 'VP8' ]
          });
        })
        .then(room => {
          dispatch({
            type: STARTED_TWILIO_CALL,
            token: _token,
            videoRoom: room,
          })
        })
        .catch((error) => {
          let errorMessage = "Errore";
          if (error && error.response && error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
          }
          dispatch({
            type: ERROR_ENTER_CALL,
            message: errorMessage,
          })
        })
  }
};

export const endTwilioCall = (room) => {
  room.disconnect();
  return {
    type: END_TWILIO_CALL,
  };
};


export const startTwilioRecording = (customerUuid, roomName, identity) => {
  return (dispatch, getState) => {
    let data = {
      room_name: roomName,
      action_type: 'start',
      publisher: identity
    }

    return axios
        .post(`${ROOT_URL}/customers/${customerUuid}/videoinspections/updaterules/`, data)
        .then((response) => {
          //console.log('START RECORDING', response);
          dispatch(
              notifSend({
                message: "Registrazione cominciata",
                kind: "success",
                dismissAfter: "2000",
              })
          );
          dispatch({
            type: START_TWILIO_RECORDING,
            recordingRoom: roomName,
            recordingIdentity: identity,
            /*resourceIdRecording: response.data.resource_id,
            sidRecording: response.data.sid,
            message: response.data.message,*/
          });
          return true;
        })
        .catch((error) => {
          dispatch(
              notifSend({
                message: messageStatus(error),
                kind: "danger",
                dismissAfter: "2000",
              })
          );
          return false;
        });
  }
}

export const stopTwilioRecording = (customerUuid, roomName, identity) => {
  return (dispatch, getState) => {
    let data = {
      room_name: roomName,
      action_type: 'stop',
      publisher: identity
    }

    return axios
        .post(`${ROOT_URL}/customers/${customerUuid}/videoinspections/updaterules/`, data)
        .then((response) => {
          //console.log('STOP RECORDING', response);
          dispatch(
              notifSend({
                message: "Registrazione conclusa",
                kind: "success",
                dismissAfter: "2000",
              })
          );
          dispatch({
            type: STOP_TWILIO_RECORDING,
            recordingRoom: roomName,
            recordingIdentity: identity,
            /*resourceIdRecording: response.data.resource_id,
            sidRecording: response.data.sid,
            message: response.data.message,*/
          });
          return true;
        })
        .catch((error) => {
          dispatch(
              notifSend({
                message: messageStatus(error),
                kind: "danger",
                dismissAfter: "2000",
              })
          );
          return false;
        });
  }
};

/**
 * Inizia una registrazione video.
 * @param {String} cid uid dell'utente che deve essere registrato
 */

export const startRecording = (cid, userUuid) => {
  return (dispatch, getState) => {
    const channelName = getState().inspection.channelName;
    const data = {
      useruid: userUuid,
      recordtype: "video",
      cname: channelName,
    };
    return axios
        .post(`${ROOT_URL}/customers/${cid}/videoinspections/record/`, data)
        .then((response) => {
          dispatch(
              notifSend({
                message: "Registrazione cominciata",
                kind: "success",
                dismissAfter: "2000",
              })
          );
          dispatch({
            type: START_RECORDING,
            resourceIdRecording: response.data.resource_id,
            sidRecording: response.data.sid,
            message: response.data.message,
          });
        })
        .catch((error) => {
          dispatch(
              notifSend({
                message: messageStatus(error),
                kind: "danger",
                dismissAfter: "2000",
              })
          );
        });
  };
};

export const stopRecording = (cid) => {
  return (dispatch, getState) => {
    const sid = getState().inspection.sidRecording;
    const resourceIdRecording = getState().inspection.resourceIdRecording;
    const channelName = getState().inspection.channelName;
    const data = {
      sid,
      resource_id: resourceIdRecording,
      cname: channelName,
    };
    return axios
        .post(`${ROOT_URL}/customers/${cid}/videoinspections/stop/`, data)
        .then((response) => {
          dispatch(
              notifSend({
                message: "Registrazione completata",
                kind: "success",
                dismissAfter: "2000",
              })
          );
        })
        .catch((error) => {
          dispatch(
              notifSend({
                message: messageStatus(error),
                kind: "danger",
                dismissAfter: "2000",
              })
          );
        });
  };
};

/**
 * Fa uno screenshot dello stream dell'uid cliccato prima di schiacciare il bottone
 * @param {String} cid customer id solito
 * @returns {function(*, *): Promise<void>}
 */
export const takeScreenshot = (cid, userUuid) => {
  return (dispatch, getState) => {
    const channelName = getState().inspection.channelName;
    const data = {
      useruid: userUuid,
      recordtype: "screenshot",
      cname: channelName,
    };
    return axios
        .post(`${ROOT_URL}/customers/${cid}/videoinspections/record/`, data)
        .then((response) => {
          dispatch({
            type: TAKE_SCREENSHOT,
            resourceIdScreenshot: response.data.resource_id,
            sidScreenshot: response.data.sid,
            message: response.data.message,
          });
          dispatch(
              notifSend({
                message: "Screenshot catturato con successo",
                kind: "success",
                dismissAfter: "2000",
              })
          );
        })
        .catch((error) => {
          dispatch(
              notifSend({
                message: messageStatus(error),
                kind: "danger",
                dismissAfter: "2000",
              })
          );
        });
  };
};

export const getAllInspections = (cid) => {
  return (dispatch, getState) => {
    return axios
        .get(`${ROOT_URL}/customers/${cid}/recordsvideoinspections/`)
        .then((response) => {
          dispatch(filterScreenshots(response.data));
          dispatch(filterVideos(response.data));
        })
        .catch((error) => {
          dispatch(
              notifSend({
                message: messageStatus(error),
                kind: "danger",
                dismissAfter: "2000",
              })
          );
        });
  };
};

const filterScreenshots = (data) => {
  return (dispatch, getState) => {
    const existingScreenshots = getState().inspection.screenshots;
    const screenshots = data.filter((file) => file.type === "screenshot");
    dispatch({
      type: LOAD_ALL_SCREENSHOTS,
      payload: [...existingScreenshots, ...screenshots],
    });
  };
};
const filterVideos = (data) => {
  return (dispatch, getState) => {
    const existingVideoInspections = getState().inspection.videoInspections;
    const videos = data.filter((file) => file.type === "video");
    dispatch({
      type: LOAD_ALL_VIDEOS,
      payload: [...existingVideoInspections, ...videos],
    });
  };
};

export const clearVideosAndScreenshots = () => {
  return {
    type: CLEAR_VIDEOS_AND_SCREENSHOTS,
  };
};


/* RECORDING ACTIONS */

export function uploadScreenshot(cID, files) {
  return (dispatch) => {
    const reqs = [];
    // eslint-disable-next-line array-callback-return
    files.map((f) => {
      let imgData = new FormData();
      imgData.append("name", f.name);
      imgData.append("content", f);
      imgData.append("file", f);
      imgData.append("content_type", f.type);
      imgData.append("type", "GENERIC");
      reqs.push(dispatch(realUpload(cID, imgData)));
    });
    return Promise.all(reqs).then(
        (res) => {
          return {status: 200};
        },
        (err) => {
          return {status: 400};
        }
    );
  };
}

function realUpload(cid, imgData) {
  const config = {
    headers: {"content-type": "multipart/form-data"},
  };
  return (dispatch) => {
    return axios
        .post(`${ROOT_URL}/customers/${cid}/pictures/`, imgData, config)
        .then((res) => {
          dispatch({
            type: UPLOAD_SCREENSHOT,
            payload: res.data,
          });
          dispatch(
              notifSend({
                message: "Screenshot saved",
                kind: "success",
                dismissAfter: 1500,
              })
          );
          return res;
        })
        .catch((error) => {
          console.log(error);
          if (error !== UNAUTHORIZED_ERROR)
            dispatch(
                notifSend({
                  message: messageStatus(error),
                  kind: "danger",
                  dismissAfter: 2000,
                })
            );
          return {status: error.status};
        });
  };
}